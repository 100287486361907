<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4 mb-0">{{ 'myAccount' | localize }}</h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form ref="companyAccountForm" lazy-validation>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-2">{{ 'name' | localize }} *</h6>
                <v-text-field
                  background-color="white"
                  v-model="name"
                  dense
                  outlined
                  :placeholder="'name' | localize"
                  :rules="[validationRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-2">{{ 'email' | localize }} *</h6>
                <v-text-field
                  background-color="white"
                  v-model="email"
                  dense
                  outlined
                  :placeholder="'email' | localize"
                  :rules="[validationRules.required, validationRules.email]"
                />
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-1 mb-2">{{ 'booking' | localize }} {{ 'email' | localize }}</h6>
                <v-text-field
                  background-color="white"
                  v-model="booking_email"
                  dense
                  outlined
                  :placeholder="'email' | localize"
                  :rules="[validationRules.email]"
                />
              </v-col>
            </v-row>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'password' | localize }}</h6>
                <v-text-field
                  autocomplete="new-password"
                  :name="`new-password_${Math.random()}`"
                  :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="password_show ? 'text' : 'password'"
                  background-color="white"
                  v-model="password"
                  dense
                  outlined
                  :placeholder="'enterPassword'|localize"
                  @click:append="password_show = !password_show"
                />
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'confirmPassword' | localize }}</h6>
                <v-text-field
                  autocomplete="confirm-password"
                  :name="`confirm-password_${Math.random()}`"
                  :append-icon="confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="confirm_password_show ? 'text' : 'password'"
                  background-color="white"
                  v-model="password_confirmation"
                  dense
                  outlined
                  :placeholder="'confirmPassword' | localize"
                  :rules="[passwordConfirmationRule]"
                  @click:append="confirm_password_show = !confirm_password_show"
                />
              </v-col>
            </v-row>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6" class="d-flex">
                <v-checkbox v-model="send_notification" hide-details class="mt-0 mb-2">
                  <template v-slot:label>
                    <span class="black--text">{{ 'notifyEmails' | localize }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
              <v-col cols="auto" class="ml-auto">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  :loading="saveLoading"
                  :disabled="saveLoading"
                  @click="updateUserSettings"
                > {{ 'save'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </template>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';

  export default {
    name: 'CompanyAccount',

    data() {
      return {
        validationRules: validationRules,
        name: '',
        email: '',
        booking_email: '',
        send_notification: true,
        password: '',
        password_show: false,
        password_confirmation: '',
        confirm_password_show: false,
        getLoading: false,
        saveLoading: false,
      };
    },
    async mounted() {
      try {
        this.getLoading = true;
        let userData = await api.getUser();
        this.setUserProfile(userData.data);
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },
    computed: {
      storeUserData() {
        return this.$store.getters.userData;
      },
      passwordConfirmationRule() {
        return this.password === this.password_confirmation || localize('passwordNotMatch');
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('myRequests'),
            disabled: false,
            to: { name: 'company-requests' },
          },
          {
            text: localize('myAccount'),
            disabled: true,
          },
        ];
      },
    },
    methods: {
      setUserProfile(userData) {
        this.name = userData.name;
        this.email = userData.email;
        this.booking_email = userData.booking_email;
        this.send_notification = userData.send_notification;
      },
      async updateUserSettings() {
        if (this.$refs.companyAccountForm.validate()) {
          try {
            this.saveLoading = true;
            await api.updateUserSettings({ send_notification: this.send_notification });
            await api.updateUser(this.queryData());
            let userData = await api.getUser();
            this.$store.commit('changeUserName', userData.data.name);
            this.$store.commit('changeUserEmail', userData.data.email);
            this.$store.commit('changeUserBookingEmail', userData.data.booking_email);
            localStorage.setItem('userData', JSON.stringify(this.storeUserData));
            methods.showGlobalSnackbar('success', localize('done'));
            this.saveLoading = false;
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      queryData() {
        let userData = {};
        userData.name = this.name;
        userData.email = this.email;
        if (this.booking_email) userData.booking_email = this.booking_email;
        if (this.password) userData.password = this.password;
        if (this.password_confirmation) userData.password_confirmation = this.password_confirmation;
        return userData;
      },
    },
  };
</script>
